.banner-link li a{
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em 1em;
    border-radius: 50px;
    color: var(--secondary-color);
    font-size: 0.9em;
    font-weight: 500;
    justify-content: center;
}

.banner-link{
    grid-template-columns: repeat(3,1fr);
    display: grid;
    gap: 1rem;
    margin-top: 3em;
}

.vertical-center{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 73px);
}

.landing-swap-bg-right-sec{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 48%;
    min-height: 100vh;
    background-color:#F9F9F9;
}

.landing-page-right-sec{
    position: relative;
    z-index: 0;
    padding-left: 4em;
}

.landing-swap-box .nav-pills .nav-link.active{
    background-color: transparent;
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--primary-color);
    border-radius: 0;
}

.landing-swap-box .nav-pills .nav-link{
    padding-left: 0;
    padding-right: 0;
    font-weight: 500;
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--secondary-color);
    border-radius: 0;
}

.landing-swap-box .nav-pills{
    gap: 2em;
}

.landing-swap-exchange-box{
    /* grid-template-columns: repeat(2,1fr); */
    /* display: grid; */
    gap: 1rem;
    position: relative;
    z-index: 0;
}

.landing-swap-exchange-conversion-left-sec{
    width: 220px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.landing-swap-exchange-conversion-label{
    display: flex;
    align-items: center;
    gap: 1em;
}

.landing-swap-exchange-conversion-right-sec{
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
}

.landing-swap-exchange-conversion-right-sec h5{
    font-size: 1em;
    font-weight: 400;
    color: var(--quinary-color);
}

.landing-swap-exchange-conversion-right-sec h3{
    font-size: 1.8em;
    font-weight: 700;
    color: var(--tertiary-color);
    margin-bottom: 0;
}


.landing-swap-exchange-card{
    background: var(--secondary-color);
    border-radius: 16px;
    padding: 2em;
    padding-top: 0;
}

.exchange-conversion-icon{
    width: 2.5em;
	height: 2.5em;
	object-fit: contain;;
}

.landing-swap-box{
    margin-bottom: 2em;
}

.text-para{
    color: var(--quaternary-color)!important;
}

.landing-swap-transfer-icon-sec{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 48%;
}

.landing-swap-exchange-btn-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
}

.landing-swap-exchange-rate-sec{
    margin-top: 1em;
}

.landing-swap-exchange-rate-sec p{ 
    color: #BFBFBF;
    font-size: 0.95em;
}

.landing-swap-exchange-rate-sec p+p{
    margin-bottom: 0;
}

.receive-token-sec{
    margin-bottom: 2em;
}

.receive-token-sec h5{
    color: var(--quinary-color);
    font-size: 1em;
}

.swap-exchange-box .or-line{
    width: 50%;
    margin: auto;
    color: rgba(59, 186, 156, 0.2);
    padding: 30px 0;
}

.swap-exchange-box .or-line:before, .swap-exchange-box .or-line:after{
    background: rgba(59, 186, 156, 0.2);
}

.swap-connect-box{
    background-color: #FBFBFB;
    color: rgba(124, 124, 124, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6em;
    font-size: 0.9em;
    font-weight: 500;
    width: 80%;
    margin: 0em auto;
}

.connect-meta-btn{
    width: 100%;
}

.connect-meta-btn button{
    background-color: #0066FF!important;
    padding: 0.6em 2em !important;
    text-align: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%);
    color: var(--secondary-color) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.4em;
    border: none;
    font-size: 1em!important;
    font-weight: 600!important;
    border: 1px solid #0066FF!important;
    width: 100%;
  }
  
  .connect-meta-btn button:hover,
  .connect-meta-btn button:focus,
  .connect-meta-btn button:active {
    background-color:#0066FF;
    border: 1px solid #0066FF;
  }

  .swap-scan-box{
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1em;
    gap: 1em;
  }

  .swap-scan-item{
    grid-template-columns: repeat(2,1fr);
    display: grid;
    gap: 1rem;
    margin-bottom: 2em;
  }

  .swap-scan-box p{
    margin-bottom: 0;
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 400;
  }

  .swap-connect-card{
    background-color: #0066FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1em;
    gap: 1em;
  }

  .swap-connect-card p{
    margin-bottom: 0;
    color: var(--secondary-color);
    font-size: 0.9em;
    font-weight: 400;
  }

  .exchange-form .input-group-text{
    background-color: transparent;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
  }

  .back-arrow-btn-sec .back-arrow-btn{
    width: 2em;
    cursor: pointer;
  }

  .exchange-flex-card{
    display: flex;
    align-items: center;
  }
